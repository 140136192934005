import React from "react";
import { WorkDay, WORKDAYS, WORKDAYS_SHORT } from "./constants";
import { AVAILABILITIES, capitalizeFirstLetter, getHourLabel, getMinuteLabel, isHourColumn } from "./logic";

export const DesktopWeekView = (props: { startHalfHour: boolean, startHour: number, endHour: number, endHalfHour: boolean }) => {
    const { endHour, startHalfHour, startHour, endHalfHour } = props;

    const cols = 5 + 1; // 5 weekdays + 1 time
    const rows = (endHour - startHour) - (startHalfHour ? 1 : 0) + (endHalfHour ? 1 : 0) + 1
    //grid-cols-${cols * 2 - 1} 
    return <div className="hidden md:inline p-2 bg-light rounded-md">

        <div style={{ gridTemplateColumns: `repeat(${cols * 2 - 1}, minmax(0, 1fr))` }}
            className={`relative grid grid-rows-1 gap-px bg-gray-200 border border-gray-200 h-auto`}>
            <div className={`col-span-1 font-semibold bg-light text-softblack text-center py-1`}>Tijdstip</div>
            {WORKDAYS_SHORT.map((weekDay, i) => {
                return <div key={i} className={`col-span-2 font-semibold bg-light text-softblack text-center p-1`}>{capitalizeFirstLetter(weekDay)}</div>
            })}
        </div>
        <div style={
            {
                gridTemplateColumns: `repeat(${cols * 2 - 1}, minmax(0, 1fr))`,
                gridTemplateRows: `repeat(${2 * rows}, minmax(0, 1fr))`,
            }
        }
            className={`hidden relative md:grid gap-px bg-gray-200 border border-gray-200 h-128`}>
            {[...Array(cols * (2 * rows)).keys()].map(i => {
                return <div key={i}
                    className={`bg-light col-span-${i % cols == 0 ? "1" : "2"}`}>
                    {isHourColumn(i, cols) && <div className="relative h-full w-full">
                        <div className="absolute top-0 right-0 text-softblack">{`${getHourLabel(i, cols, startHour, startHalfHour)}:${getMinuteLabel(i, cols, startHalfHour)}`}</div>
                    </div>}
                </div>
            })}
            <div style={
                {
                    gridTemplateColumns: `repeat(${cols * 2 - 1}, minmax(0, 1fr))`,
                    gridTemplateRows: `repeat(${2 * rows}, minmax(0, 1fr))`,
                }}
                className={`absolute inset-0 grid`}>

                {
                    AVAILABILITIES.map(({ startHour: startH, startMinutes, day, doctor }, i) => {

                        // factor 2 is because double of rows
                        const startRow = 2 * (startH - startHour) + (startMinutes == 30 ? 1 : 0);

                        const colIdx = WORKDAYS.indexOf(day.toLowerCase() as WorkDay) + 1

                        return (<div key={`av-deskt${i}`}
                            style={{
                                gridColumnStart: colIdx * 2,
                                gridRowStart: startRow,
                            }}
                            className={`col-span-2 row-span-2 p-1`}>
                            <div className={`w-full bg-red-200 h-full flex justify-center text-center items-center p-2 ${doctor.includes("Sophie") ? "bg-cal2 text-softblack " : "bg-cal1"}`}>
                                {doctor}
                            </div>
                        </div>)
                    })
                }
            </div>
        </div></div>

}