import { useStaticQuery, graphql } from 'gatsby';
export const usePraktijkImages = () => {
  const {
    frontImage, balieImage, roomImage
  } = useStaticQuery(
    graphql`
    query PraktijkFrontImage {
        frontImage: allImageSharp(filter: {original: {src: {regex: "/praktijk-front/"}}}) {
          nodes {
            gatsbyImageData
          }
        }
        balieImage: allImageSharp(filter: {original: {src: {regex: "/praktijk-balie/"}}}) {
          nodes {
            gatsbyImageData
          }
        }
        roomImage: allImageSharp(filter: {original: {src: {regex: "/praktijk-kamer/"}}}) {
          nodes {
            gatsbyImageData
          }
        }
      }      
    `,
  );
  return {front: frontImage.nodes[0].gatsbyImageData, balie: balieImage.nodes[0].gatsbyImageData, room: roomImage.nodes[0].gatsbyImageData};
};