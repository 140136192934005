import { useStaticQuery, graphql } from "gatsby";

export const useTeamMembers = () => {
    const {
      fre, sophie, ellen, nancy, newColl
    } = useStaticQuery(
      graphql`
      query TeamMembers {
          fre: allImageSharp(filter: {original: {src: {regex: "/team-Fr/"}}}) {
            nodes {
              gatsbyImageData
            }
          }
          sophie: allImageSharp(filter: {original: {src: {regex: "/team-Sophie/"}}}) {
            nodes {
              gatsbyImageData
            }
          }
          ellen: allImageSharp(filter: {original: {src: {regex: "/team-Ellen/"}}}) {
            nodes {
              gatsbyImageData
            }
          }
          nancy: allImageSharp(filter: {original: {src: {regex: "/team-Nancy/"}}}) {
            nodes {
              gatsbyImageData
            }
          }
          newColl: allImageSharp(filter: {original: {src: {regex: "/team-new-coll/"}}}) {
            nodes {
              gatsbyImageData
            }
          }
        }      
      `,
    );
  
    return {fre: fre.nodes[0].gatsbyImageData, newColl: newColl.nodes[0].gatsbyImageData, sophie: sophie.nodes[0].gatsbyImageData,nancy: nancy.nodes[0].gatsbyImageData, ellen: ellen.nodes[0].gatsbyImageData}
  }