import React from "react";
import { BEPHONE } from "./availabilities/constants";
import MyLink from "./MyLink";
import SectionTitle from "./SectionTitle";

const Question = (props: { title: string, children: React.ReactChild | React.ReactChild[] }) => {
    return (
        <details className="mb-4">
            <summary className="font-semibold bg-cards text-light rounded-md py-2 px-4">
                {props.title}
            </summary>

            <p className="px-4 py-2 mt-2 rounded-lg text-softblack text-justify">
                {props.children}
            </p>
        </details>)
}
const Faq = () => {
    return (
        <>
        {/* className="container px-5 py-24 mx-auto" */}
            <SectionTitle title="Praktische informatie"/>
            <div className="text-center mb-20">
                
                <p className="text-base text-softblack leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                    Hier vind je alvast een antwoord op enkele vragen. Moest dit niet zo zijn, <MyLink href={`tel:${BEPHONE}`}>bel gerust naar ons secretariaat!</MyLink>
                </p>
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                <div className="w-full lg:w-1/2 px-4 lg:py-2">

                    <Question title="Elektronische voorschriften">
                        Vanaf heden zijn de voorschriften die u aanvraagt elektronisch beschikbaar via <MyLink href="https://mijngezondheid.be">mijngezondheid.be</MyLink>.
                        Dit betekent ook dat de apotheek deze kan uitlezen via uw identiteitskaart.
                        Gelieve steeds voldoende voorschriften te vragen tijdens de consultatie en plan tijdig een opvolgafspraak in.
                        Het uitschrijven van voorschriften gebeurt enkel tijdens een consultatie of in uitzonderlijke gevallen via telefonisch contact.
                    </Question>
                    <Question title="Verloop van een consultatie">
                        Per patiënt voorzien wij een kwartier. Indien je voor meerdere personen een advies nodig hebt, maak dan ook steeds een extra afspraak per persoon.
                        Voldoende voorschriften voor medicatie worden tijdens de consultatie door je arts meegegeven. Het is alleszins niet de bedoeling deze telefonisch aan te vragen.
                    </Question>
                    <Question title="Online afspraak maken lukt niet">
                        Tot nader order weten we niet wanneer we terug volgens het oude schema kunnen werken en online-afspraken vrijkomen, daarom is het niet mogelijk om momenteel online een afspraak te boeken. Denkt u een arts nodig te hebben, stel uw zorg niet uit maar bel naar de praktijk!
                    </Question>
                    <Question title="Resultaten van onderzoek">
                        Enkel niet afwijkende resultaten zullen worden meegedeeld. Bij een afwijkend resultaat wordt een afspraak gemaakt met de arts die het onderzoek heeft aangevraagd.
                    </Question>
                </div>
                <div className="w-full lg:w-1/2 px-4 lg:py-2">
                    <Question title="Globaal Medisch Dossier (GMD)">
                        Het GMD bevat al uw medische gegevens (operaties, chronische ziekten, lopende behandelingen…). Het maakt een betere individuele begeleiding en een beter overleg tussen de artsen mogelijk.
                        Als u een GMD aanvraagt bij uw huisarts, krijgt u tot 30% meer terugbetaald voor een raadpleging van uw huisarts en krijgt u het supplement (€4) voor de avondraadpleging (na 18 uur) volledig terugbetaald.
                        Voor de aanvraag van het GMD volstaat het dat u jaarlijks uw e-ID meebrengt naar de consultatie.
                    </Question>
                    <Question title="Sumehr">
                        In het kader van digitalisering en interdisciplinaire samenwerking zal uw arts u vragen om een digitale samenvatting van uw dossier te maken (Sumehr).
                        Deze sumehr dient om een goed beeld van uw gezondheidstoestand te kunnen maken en te delen met andere vaste artsen. Dit is volledig kostenloos en u kan hiervoor uw toestemming geven.
                        Op <MyLink href='http://www.vitalink.be/beknopte-medische-dossiers-delen-sumehr'>deze website</MyLink> kan u terecht voor meer informatie.
                    </Question>

                    <Question title="GDPR">
                        Een uitgebreide uitleg vindt u op <MyLink>deze pagina</MyLink> (moet nog gebeuren).
                    </Question>
                </div>
            </div>
        </>)
}

export default Faq;