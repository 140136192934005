import React, { ReactChild, ReactChildren, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { format } from "date-fns"
import Layout from "../components/layout/Layout";
import LabelText from "../components/LabelText";
import { toHTML } from "../helperFunctions";
import AvailabilityPhoneLabelLazy from "../components/availabilities/AvailabilityPhoneLabelLazy";
import AvailabilitiesWeeklyView from "../components/availabilities/AvailabilitiesWeeklyView";
import Faq from "../components/Faq";
import Stethos from "../components/stethos";
import { Section, Parallax as P, SectionHeader, MinimalSection } from "../components/layout/Parralax";
import { BEPHONE, PHONE } from "../components/availabilities/constants";
import LocationMap from "../components/LocationMap";
import Modal from "../components/Form";
import { GatsbyImage, GatsbyImageProps, ImageDataLike, StaticImage } from "gatsby-plugin-image"
import { usePraktijkImages } from "../hooks/usePraktijkImages";
import { useTeamMembers } from "../hooks/useTeamMembers";
import SectionTitle from "../components/SectionTitle";


const PARALLAX_CLASSNAMES = "bg-cover bg-center top-0 sticky z-0 h-screen w-screen mt-[-100vh]";


/**
 * 
 https://play.tailwindcss.com/
 */

const ROUNDED_BG = true;
const HERO_ROUNDED_BG = false;

//text-softblack
const Card = ({ name, smallText, id, image }: { image: any, name: React.ReactFragment, bgTailwindName: string, smallText?: React.ReactFragment, id?: string }) => {
  return (
    <div id={id} className="bg-cards flex flex-col justify-center p-10 shadow-lg rounded-2xl w-64 h-96">
      <GatsbyImage className="rounded-full w-40 h-40 mx-auto bg-cover bg-center" imgClassName="rounded-full w-40 h-40 mx-auto bg-cover bg-center" alt={"teamlid"} image={image} />
      <div className="m-auto pt-1" >
        <p className="text-2xl font-medium mt-2 text-center whitespace-pre-wrap">{name}</p>
        {smallText &&
          <p className="text-center">
            {smallText}
          </p>
        }
      </div>

    </div>
  )
}
//md:space-y-0 md:space-x-2
const TeamCards = (props: { showModal: () => void, newCollegue: ImageDataLike, teamMembers: { name: string, photo: ImageDataLike }[] }) => {

  const { fre, sophie, ellen, nancy, newColl } = useTeamMembers();

  return (<>
    <SectionTitle title="Ons team in de groepspraktijk" />
    <div className="flex flex-wrap md:flex-row gap-10 items-center justify-center">
      {/* {
        (props.teamMembers??[]).map((item, i) => 
          <Card key={`member-${i}`} name={item.name} image={item.photo} bgTailwindName={""}></Card>
        )

      } */}
      <Card name={"Dr. De Coninck Frédéric"} bgTailwindName={""} image={fre} />
      <Card name={"Dr. Reyntiens Sophie"} bgTailwindName={""} image={sophie} />
      <Card name={"Nancy"} bgTailwindName={""} image={nancy} />
      <Card name={"Ellen"} bgTailwindName={""} image={ellen} />

      <Card bgTailwindName={""}
        id={'versterk'}
        name={"Nieuwe collega?"}
        image={newColl}
        smallText={<>Wij zoeken nieuwe collega's! Interesse? <button onClick={() => props.showModal()}>Klik hier</button></>}
      />

    </div>
  </>)
    ;
}

const Mededeling = (props: { html: any }) => {
  return (
    <>
      <SectionTitle title="Mededeling" />
      <div className={"text-softblack text-justify"} dangerouslySetInnerHTML={{ __html: props.html }} />
    </>
  )
}
interface Post {
  message: string,
  datetime: string,
  index: number
}
const Information = ({ }) => (<div className="container lg:pr-32 xl:pr-48 text-softblack">
  <h3 className="text-3xl font-semibold leading-tight uppercase">Online afspraak</h3>
  <p className="mt-8 text-xl font-light leading-relaxed">
    Maak snel en makkelijk je afspraak online.{' '}
    <a href={"#"}>Klik hier voor meer informatie.</a>
  </p>
</div>)


const Post = ({ message, datetime, index }: Post) => {
  return <div key={`post-index-${index}`}
    className={`${index % 2 ? "bg-gray-100" : "bg-gray-200"} p-4
        text-left whitespace-pre-wrap`}>
    <div
      className={"pb-2 italic text-gray-500"}>{`Gepubliceerd op ${format(new Date(datetime), 'MM/dd/yyyy')}`}</div>
    <div dangerouslySetInnerHTML={{ __html: toHTML(message) }} />
  </div>
}

const CompanyInfo = (props: { companyname: string }) => (<> <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
  {/*{JSON.stringify(image)}*/}
</h1>
  <p className="text-2xl lg:text-3xl mt-6 font-light">
    {`${props.companyname} - Schiplaken`}
  </p>
  <p className="text-xl font-light" >Dr. De Coninck en Dr. Reyntiens<br /><a className="cursor-pointer hover:text-accent text-white"
    href={'tel:+3215621104'}>Tel: 015621104</a></p>
  <div className="mt-3">
    <AvailabilityPhoneLabelLazy />
  </div>
  <p className="mt-4">
    <a className="py-2 px-2 font-medium text-darkblue bg-accent rounded hover:bg-cal1 uppercase"
      href={'https://secure.introlution.be/mijnonlineagenda_cmode/default.aspx?domain=praktijkdcr.be&culture=nl-BE'}>Maak
      afspraak</a>
  </p>
  <p className="text-gray-600 mt-2">
    <a className="cursor-pointer hover:text-accent text-white" href="#versterk" >Of versterk ons team</a>
  </p></>)
//h-60 md:ml-10 lg:ml-40 mt-8 md:mt-16 mx-auto transform rotate-12 md:h-52 lg:h-auto lg:w-40
const Hero = (props: { companyname: string }) => (
  <section className={`bg-gradient-to-r from-softblue to-darkblue text-softblack ${HERO_ROUNDED_BG ? "rounded-b-2xl" : ""}  w-screen`}>
    <div className="container mx-auto px-8 text-light flex flex-col md:flex-row justify-center md:justify-between h-screen md:h-96 pb-8 pt-12">
      <div className="portrait:w-[30vw] md:portrait:w-[20vw] md:w-[14vw] lg:w-40 mx-auto md:my-auto transform transform-gpu rotate-12 justify-center fill-current">
        <Stethos />
        {/* <Church/> */}
      </div>

      <div className="text-center mt-8 lg:text-left lg:w-1/2 items-center ">
        <CompanyInfo companyname={props.companyname} />
      </div>
    </div>
  </section>
)

const MyContainer = (props: React.HTMLAttributes<HTMLElement>) => {
  return <div {...props} className={"container mx-auto py-20 px-8 xl:mx-auto" + (props.className ?? '')} />
}

const MyStickyImage = (props: { image: GatsbyImageProps["image"], alt: string }) => {
  return (<GatsbyImage style={{ position: "sticky" }} image={props.image} alt={props.alt} className={PARALLAX_CLASSNAMES} />
  );
}

// eslint-disable-next-line
export const IndexPageTemplate = (props: any) => {

  const [showModal, setShowModal] = React.useState(false);// false
  const { front, balie, room } = usePraktijkImages();
  const {
    companyname,
    html,
    team,
    newCollegue
  } = props;
  // console.log("team",team, "front", front)
  return (
    <>
      <Layout>
        <MinimalSection className={`bg-light min-h-screen md:min-h-0 flex flex-col items-center ${ROUNDED_BG ? "rounded-3xl mb-5" : ""}`}>
          <Hero companyname={companyname}></Hero>
          <MyContainer>
            <Mededeling html={html} />
          </MyContainer>
        </MinimalSection>

        <MyStickyImage image={balie} alt={"Secretariaat"} />
        {/* <Parallax imagePath="../img/praktijk.jpg" className={`bg-practice`} /> */}
        <SectionHeader />

        <Section className={`bg-light flex flex-col items-center justify-center ${ROUNDED_BG ? "rounded-3xl -mt-5 mb-5" : ""}`}>
          <MyContainer>
            <TeamCards newCollegue={newCollegue} showModal={() => setShowModal(true)} teamMembers={team} />
          </MyContainer>
        </Section>

        <MyStickyImage image={front} alt={"Gevel"} />
        {/* <Parallax className={`bg-house`} /> */}
        <SectionHeader />

        <Section id={'contact'} className={`flex bg-light flex-col items-center justify-center ${ROUNDED_BG ? "rounded-3xl -my-5" : ""} `}>
          {/* <div className={`container mx-auto py-20 px-8 xl:mx-auto`}>  py-10 */}
          <MyContainer>
            <SectionTitle title="Praktijk" />
            <div className="flex flex-row justify-center">
              {/* md:w-11/12 */}
              <div className="bg-cards flex flex-col justify-center shadow-lg rounded-2xl p-5 md:p-8 w-[90vw] h-[60vw] md:w-full md:h-96 select-none">
                <div className="bg-light p-2 rounded-md w-full h-full">
                  <LocationMap />
                </div>
              </div>
            </div>

            <p className="pt-5 md:pt-10 text-softblack text-center md:text-justify pb-10">De praktijk is gelegen in de <span className=" font-semibold">Bieststraat 223 in Schiplaken</span>. Ons secretariaat is iedere werkdag bereikbaar tussen 8u00 tot 12u30 en 13u30 tot 19u00 op <a href={`tel:${BEPHONE}`} className="hover:text-accent font-semibold">{PHONE}</a>. Bovendien is elke dag een arts bereikbaar tijdens het belmoment zoals aangegeven hieronder.</p>
            <SectionTitle title="Telefonische bereikbaarheid per arts" />
            <AvailabilitiesWeeklyView></AvailabilitiesWeeklyView>
            {/* </div> */}
          </MyContainer>
        </Section>


        <MyStickyImage image={room} alt={"Praktijk"} />
        {/* <Parallax className="bg-practice-room" /> */}
        <SectionHeader />

        <Section className={`bg-light flex flex-col justify-center ${ROUNDED_BG ? "rounded-3xl" : ""}`}>
          <MyContainer>
            <Faq />
          </MyContainer>
        </Section>

        {/* DUMMY ELEMENT Otherwise it won't work as expected on Safari */}
        <div className={`"bg-transparant -mb-20" ${PARALLAX_CLASSNAMES}`} />
        {/* <Parallax className="bg-transparant -mb-20"></Parallax> */}
        <SectionHeader className="h-[0] max-h-0 -mb-20" />

        <Modal hideModal={() => setShowModal(false)} show={showModal} />
      </Layout>
    </>
  );
};

IndexPageTemplate.propTypes = {

  companyname: PropTypes.string,
  html: PropTypes.string,
};

const IndexPage = ({ data }: any) => {
  const { newCollegue } = data;
  const { frontmatter, html } = data.markdownRemark;
  // console.log(frontmatter, newCollegue)
  return (
    <IndexPageTemplate
      companyname={frontmatter.companyname}
      html={html}
      team={frontmatter.team}
      newCollegue={newCollegue}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    newCollegue: file(name: {eq: "new-collegue"}) {
      childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        html
      frontmatter {
        companyname
      }
    }
  }
`;
/*
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
*/