import React from "react";
import { DesktopWeekView } from "./DesktopWeekView";
import { BOUNDARY_AVAILABILITIES } from "./logic";
import { MobileWeekView } from "./MobileWeekView";

const AvailabilitiesWeeklyView = () => {
    const { earliest, end } = BOUNDARY_AVAILABILITIES;
    const { startHour, startMinutes } = earliest;
    const { endHour, endMinutes } = end;

    const startHalfHour = startMinutes == 30;
    const endHalfHour = endMinutes == 30;
    return (
        // <p>{JSON.stringify(BOUNDARY_AVAILABILITIES)}</p>  w-11/12
        <div className="flex flex-col items-center ">
            <div className="bg-cards flex flex-col justify-center shadow-lg rounded-2xl w-full p-5 md:p-8 select-none">
                <MobileWeekView startHour={startHour} startHalfHour={startHalfHour} endHour={endHour} endHalfHour={endHalfHour} />
                <DesktopWeekView startHour={startHour} startHalfHour={startHalfHour} endHour={endHour} endHalfHour={endHalfHour} />
            </div>
        </div>


    )
}

export default AvailabilitiesWeeklyView