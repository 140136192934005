export const WORKDAYS = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag"] as const;
export const WEEKDAYS = [...WORKDAYS, "zaterdag", "zondag"] as const;

export const WORKDAYS_SHORT = ["Ma", 'Di', 'Woe', 'Do', 'Vrij'];
export const WEEKDAYS_SHORT = [...WORKDAYS_SHORT, 'Za', 'Zo'];

export type WorkDay = typeof WORKDAYS[number];
export type WeekDay = typeof WEEKDAYS[number];

export const PHONE = '015621104'
export const BEPHONE = '+32'+PHONE;

export interface _Availability {
    day: WorkDay,
    time: string,
    doctor: string
}

export interface TimeInfo {
    startHour: number,
    endHour: number,
    startMinutes: number, 
    endMinutes: number,
}
export interface Availability extends TimeInfo, Pick<_Availability, 'doctor' | 'day'> {}


export const _AVAILABILITIES: _Availability[] = [
    {day: 'maandag', 'time': '12.30u - 13.30u', 'doctor': 'Dr. Reyntiens Sophie'}, 
    {day: 'maandag', 'time': '17.00u - 18.00u', 'doctor': 'Dr. De Coninck Frédéric'}, 
    {day: 'dinsdag', 'time': '12.30u - 13.30u', 'doctor': 'Dr. Reyntiens Sophie'}, 
    {day: 'woensdag', 'time': '17.00u - 18.00u', 'doctor': 'Dr. De Coninck Frédéric'}, 
    {day: 'donderdag', 'time': '17.00u - 18.00u', 'doctor': 'Dr. Reyntiens Sophie'}, 
    {day: 'vrijdag', 'time': '12.30u - 13.30u', 'doctor': 'Dr. De Coninck Frédéric'}
]