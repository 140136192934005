import _, { round } from "lodash";
import { _AVAILABILITIES, Availability, TimeInfo, WorkDay, WORKDAYS, _Availability, WEEKDAYS, WeekDay } from "./constants";
import Holidays from "date-holidays"

export type Nullable<T> = T | null;

const hd = new Holidays();
hd.init('BE');

export function isHourColumn(rowNumber: number, nbCols: number){
    return rowNumber % nbCols == 0
}

export function isEvenRow(rowNumber: number, nbCols: number){
    return (rowNumber/nbCols)%2 == 0
}
export function getMinuteLabel(itemNumber: number, nbCols: number, startHalfHour: boolean){
    // start with 30 min
    if (isEvenRow(itemNumber, nbCols)){
        return startHalfHour? '30' : "00"
    } else {
        return startHalfHour? '00' : "30"
    }
}

export function getHourLabel(itemNumber: number, nbCols: number, startHour:number, startHalfHour: boolean){
    let hour = startHour;
    if (isEvenRow(itemNumber, nbCols)){
        hour += itemNumber/nbCols/2;                                
    } else {
        if (startHalfHour)
            hour += (itemNumber+nbCols)/nbCols/2;
        else 
            hour += (itemNumber-nbCols)/nbCols/2;
    }
    return hour.toString()
}

function generateTimeLabels(start: string, nbIntervals: number){
    const result = [start];
    let startNb = new Number(start.slice(0,2)).valueOf();
    const startSecondPart = new Number(start.slice(3)).valueOf()
    for (let i = 1; i< nbIntervals; i++){
        const newSecondPart = (startSecondPart + i * 30 ) %60
        startNb = newSecondPart > 0 ? startNb: startNb + 1; 
        result.push(`${startNb}.${newSecondPart> 0? newSecondPart:'00'}`)
    }
    return result;
}

export const AVAILABILITIES = constructAvailabilities(_AVAILABILITIES).sort((a,b)=> {
    // Maybe better to construct real date objects and use that
    if (a.startHour > b.startHour)
        return 1;
    if (a.startHour < b.startHour){
        return -1;
    }

    if (a.startMinutes > b.startMinutes){
        return 1
    } if (a.startMinutes < b.startMinutes) {
        return -1
    }
    return 0;
});


export const BOUNDARY_AVAILABILITIES = {
    earliest: AVAILABILITIES[0],
    end: AVAILABILITIES[AVAILABILITIES.length-1]
}


export function getNbOfIntervalsBetweenTwoHours(hour1: number, hour1Min: number, hour2: number, hour2Min: number, intervalMinutes:number = 30){
    const h = 60;
    if (h%intervalMinutes != 0){
        throw new Error("intervalMinutes should divide 60 minutes")
    }

    let result = (hour1-hour2)*Math.round(h/intervalMinutes); // 30 min intervals

    if (hour1Min>0){
        result -= 1;
    }

    if (hour2Min>0){
        result += 1;
    }

    return result;

}



function isToday(day: string){
    const today = new Date().getDay();
    if (today > 5){
        // Sat or sunday
        return false;
    }
    if (WORKDAYS.indexOf(day.toLowerCase() as WorkDay) >= 0){
        return ((WORKDAYS.indexOf(day.toLowerCase() as WorkDay)+1) == today)
    } else {
        throw new Error("No valid index found for day");
    }
}

export function parseRawDateStrings(dateRangeString: string): TimeInfo{
    if (dateRangeString.length != '12.30u - 13.30u'.length){
        throw new Error("Date string given with incorrect format")
    }

    const datestringStart = dateRangeString.slice(0,5);
    const datestringEnd = dateRangeString.slice(9,14);

    const startHour = Number(datestringStart.slice(0,2))
    const endHour = Number(datestringEnd.slice(0,2))

    const startMinutes = Number(datestringStart.slice(3,5))
    const endMinutes = Number(datestringEnd.slice(3,5))

    return {
        startHour,
        endHour,
        startMinutes,
        endMinutes,
    }
}

function constructAvailabilities(availabilities: _Availability[]): Availability[]{
    return availabilities.map(({time, doctor, day}) => {
        const timeInfo = parseRawDateStrings(time);
        return {...timeInfo, doctor, day}
    })
}

function inTimeWindow(timeInfo: TimeInfo): Boolean {

    const {startHour, endHour, endMinutes, startMinutes} = timeInfo;

    const today = new Date();
    const nowH = today.getHours();

    if (nowH >= startHour && nowH <= endHour){
        // check minutes
        const nowM = today.getMinutes();

        // Only check boundaries:
        if (nowH == startHour){
            return nowM >= startMinutes;
        } else if (nowH == endHour){
            return nowM <= endMinutes;
        }
        return true;

    }
    return false;
}


export function getCurrentAvailability(): Nullable<Availability> {

    const currDate = new Date();
    const currDayIdx = currDate.getDay();

    let result = null;

    AVAILABILITIES.forEach(a => {
        let idx = WEEKDAYS.indexOf(a.day.toLowerCase() as WeekDay);
        if (idx != -1){
            // valid idx
            idx += 1; // Because getDay gives not 0
            if (idx == currDayIdx){
                
                if (inTimeWindow(a)){
                    result = a;
                }
            }
        } else {
            console.error("inTimeWindow -- No valid index found for day");
        }
        
    })
    return result;
}



function aggregateAvailabilities(availabilities: Availability[]){
    const days = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"];
    const result: Availability[][] = [];

    days.forEach(d => {
        result.push(availabilities.filter(a=>a.day == d));
    })

    return result;
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  

export function isDuringWorkingHours(date: Date, options?: {startHour: number, endHour: number, weekendIncluded: boolean}): boolean {
    // Saturday and sunday are not within working hours

    const {startHour=8, endHour=19, weekendIncluded=false} = options ?? {}
    // sunday = 0 and saturday = 6
    if ((((date.getDay() == 0) || (date.getDay() == 6)) && !weekendIncluded) || hd.isHoliday(date)) return false;
    return date.getHours() >= startHour && date.getHours() < endHour;
}