import React from "react"


export const Parallax = (props: React.HTMLAttributes<HTMLElement>) => {
    return (
        <div {...props} className={["bg-cover bg-center top-0 sticky z-0 h-screen w-screen mt-[-100vh]", props.className ?? ""].join(' ')} />
    )
}

export const SectionHeader = (props: React.HTMLAttributes<HTMLElement>) => {
    // h-[70vh] was orig
    return (
        <div {...props} className={["relative w-full h-[40vh] flex justify-start items-end z-20", props.className ?? ""].join(' ')} />
    )
}

export const MinimalSection = (props: React.HTMLAttributes<HTMLElement>) => {
    // style={{transform: 'translate3d(0,0,0) translateZ(1000px)'}}  
    // https://blog.frobieter.de/fix-safari-transform-z-index-bug/
    // All elements that overlay this element should have the following properties set!
    return <div {...props} style={{...props.style, transform: 'translate3d(0,0,0) translateZ(1000px)'}} className={["relative w-full z-10", props.className ?? ""].join(' ')} />

}
export const Section = (props: React.HTMLAttributes<HTMLElement>) => {
    //px-12 py-[calc(20vw-70px)] last:py-[calc(20vw-70px)]
    return <div {...props} style={{...props.style, transform: 'translate3d(0,0,0) translateZ(1000px)'}} className={["relative w-full min-h-screen z-10 last:min-h-[50vh] last:px-12 ", props.className ?? "", ].join(' ')} />

}
export const HeaderText = (props: React.HTMLAttributes<HTMLElement>) => {
    return <div {...props} className={[ "h-auto pr-0 py-5 pl-[calc(20vw-70px)] mb-10 text-white bg-[rgba(16,32,48,0.5)]", props.className ?? ""].join(' ')} />
}
