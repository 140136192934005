import React, { useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { WORKDAYS_SHORT } from "./constants";
import { AVAILABILITIES, getHourLabel, getMinuteLabel } from "./logic";


function mapDayIdx(day: number) {
    // Zo = 0; Ma = 1; ... ; Za = 1;
    // 1 -> 0, 2 -> 1, 6 -> 5
    if (day - 1 < 0) {
        return 6
    } else {
        return day - 1
    }
}
const currDayIdx = mapDayIdx(new Date().getDay());

const MobileEntry = ({ startHour, startMinutes, firstHour, i, doctor }) => {

    const [startRow, setStartRow] = useState(-1);
    // factor 2 is because double of rows
    useEffect(() => {
        const res = 2 * (startHour - firstHour) + 1 + (startMinutes == 30 ? 1 : 0);
        if (res != startRow) {
            setStartRow(res);
        }
    }, [startHour, startMinutes, firstHour, i, doctor]);

    return (<div key={`av-mob-${i}`} style={{ gridRowStart: startRow }}
        className={`row-span-2 col-start-2 col-span-2 p-1`}>
        <div className={`w-full text-softblack bg-red-200 h-full flex justify-center text-center items-center p-2 ${doctor.includes("Sophie") ? "bg-cal1" : "bg-cal2"}`}>
            {doctor}
        </div>
    </div>)
}

export const MobileWeekView = (props: { startHalfHour: boolean, startHour: number, endHour: number, endHalfHour: boolean }) => {
    const { startHalfHour, startHour, endHalfHour, endHour } = props;
    const [currentDay, setCurrentDay] = useState(WORKDAYS_SHORT[currDayIdx < 5 ? currDayIdx : 0]);

    function moveLeft(event: any) {
        event.preventDefault();
        const currIdx = WORKDAYS_SHORT.indexOf(currentDay);
        if (currIdx > 0) {
            setCurrentDay(WORKDAYS_SHORT[currIdx - 1])
        }
    }

    function moveRight(event: any) {
        event.preventDefault();
        const currIdx = WORKDAYS_SHORT.indexOf(currentDay);
        if (currIdx < WORKDAYS_SHORT.length - 1) {
            setCurrentDay(WORKDAYS_SHORT[currIdx + 1])
        }
    }

    const cols = 2;
    const rows = (endHour - startHour) - (startHalfHour ? 1 : 0) + (endHalfHour ? 1 : 0) + 1
    console.log("Cols", cols, "rows", rows);

    return (
        <div className="md:hidden p-2 bg-light rounded-md text-softblack">
            <div style={
                {
                    gridTemplateColumns: `repeat(${cols + 1}, minmax(0, 1fr))`,
                    gridTemplateRows: `repeat(${2 * rows + 1}, minmax(0, 1fr))`,
                }
            } className={`relative grid gap-px bg-gray-200 border border-gray-200`}>
                <div className={`col-span-1 col-start-1 row-start-1 font-semibold bg-light text-center text-softblack`}>Tijdstip</div>
                <div className={`col-span-2 row-start-1 font-semibold bg-light text-center z-10`}>
                    <div className="ml-3 mr-3 flex flex-row justify-between items-center">
                        <HiChevronLeft onClick={moveLeft} className="rounded-full bg-softblue text-light cursor-pointer h-5 w-5" />
                        <span className="text-softblack">{currentDay}</span>
                        <HiChevronRight onClick={moveRight} className="rounded-full bg-softblue text-light cursor-pointer h-5 w-5" />
                    </div>
                </div>
                {[...Array(cols * (2 * rows)).keys()]
                    .map(i => {
                        return <div key={i}
                            style={{
                                gridRowStart: (Math.floor(i / cols) + 2),
                                gridColumnStart: (i % (cols) == 0) ? 1 : 2,
                                gridColumn: `span ${(i % (cols) == 0) ? 1 : 2} / span ${(i % (cols) == 0) ? 1 : 2}`,
                            }}
                            className={`bg-light col-s h-6 text-center text-softblack`}>
                            {i % (cols) == 0 &&
                                `${getHourLabel(i, cols, props.startHour, props.startHalfHour ?? false)}:${getMinuteLabel(i, cols, !!props.startHalfHour)}`}
                        </div>;
                    })
                }

                <div style={
                    {
                        gridTemplateColumns: `repeat(${cols + 1}, minmax(0, 1fr))`,
                        gridTemplateRows: `repeat(${2 * rows + 1}, minmax(0, 1fr))`,
                    }
                } className={`absolute inset-0 grid`}>

                    {AVAILABILITIES.filter(a => a.day.startsWith(currentDay.toLowerCase())).map(({ startHour, startMinutes, doctor }, i) =>
                        <MobileEntry startHour={startHour} startMinutes={startMinutes} firstHour={props.startHour} i={i} doctor={doctor} />
                    )}
                </div>
            </div>
        </div>
    )
}