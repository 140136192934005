import React, { useEffect } from 'react';
import kwesforms from 'kwesforms';


function Modal(props) {
    // style={{transform: 'translate3d(0,0,0) translateZ(1000px)'}} 
    
    useEffect(() => kwesforms.init(), [props.show])
    return (
      <>
        {props.show ? (
          <>
            <div 
                style={{transform: 'translate3d(0,0,0) translateZ(1000px)'}}  
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-20 inset-0 outline-none focus:outline-none text-softblack"
            >
              <div className="relative flex items-center px-4 w-full max-w-md h-full  md:h-auto">
  
                <div className="relative z-50 bg-light rounded-lg shadow dark:bg-gray-700">
                  <div className="flex justify-end p-2">
                    <button onClick={() => props.hideModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                  </div>
                  <form className="kwes-form px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8 " action="https://kwesforms.com/api/foreign/forms/DBv9NqsLl4YqTTQqxifk">
                    <h3 className="text-xl font-medium text-gray-900 dark:text-white">Laat uw gegevens achter...</h3>
                    <p>Geef gerust een seintje of spring eens binnen.</p>
                    <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Emailadres</label>
                      <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
                    </div>
                    <div>
                      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Naam en voornaam</label>
                      <input type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Dokter Strange" required />
                    </div>
                    <div>
                      <label htmlFor="riziv" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Riziv nummer</label>
                      <input type="number" name="riziv" id="riziv" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="12345678" required />
                    </div>
                    <button type="submit" className="w-full text-white bg-accent hover:bg-softblue focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Verstuur</button>
  
                  </form>
                </div>
              </div>
            </div>
            <div style={{transform: 'translate3d(0,0,0) translateZ(1000px)'}} className="opacity-25 fixed inset-0 z-10 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }

export default Modal;