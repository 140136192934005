import React from "react"

const ClientSideOnlyLazy = React.lazy(() =>
  import("./AvailabilityPhoneLabel")
)
export const AvailabilityPhoneLabelLazy = () => {
  const isSSR = typeof window === "undefined"

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy />
        </React.Suspense>
      )}
    </>
  )
}

export default AvailabilityPhoneLabelLazy